<template>
  <div>
    <div class="content-box mt20px">
      <div class="content-main">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :inline="true"
          :label-position="$config.labelPosition"
          label-width="150px"
        >
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="规则类别" prop="regulationType">
                <el-radio-group
                  v-model="form.regulationType"
                  v-for="(item, index) in $enums_hw.operatingRulesList.list"
                  :key="index"
                  disabled
                  @change="redioChange"
                >
                  <el-radio :label="item.label">{{ item.value }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="规则名称" prop="regulationName">
                <el-input v-model="form.regulationName" placeholder="请输入" maxlength="25"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item
                label="区域名称"
                prop="regulationArgs.areaId"
                v-if="redioType === 0 || redioType === 1"
                class="area-select"
              >
                <el-select
                  v-model="form.regulationArgs.areaId"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in areaOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <el-button type="primary" size="mini" @click="addAreaName">新增</el-button>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item
                class="time-step"
                label="时间范围"
                prop="regulationArgs.duration"
                v-if="redioType === 0 || redioType === 1 || redioType === 2 || redioType === 3"
              >
                <el-time-select
                  placeholder="起始时间"
                  v-model="form.regulationArgs.duration[0]"
                  :picker-options="{
                    start: '00:00',
                    step: '00:05',
                    end: '23:55'
                  }"
                ></el-time-select>
                <el-time-select
                  placeholder="结束时间"
                  v-model="form.regulationArgs.duration[1]"
                  :picker-options="{
                    start: '00:00',
                    step: '00:05',
                    end: '23:55',
                    minTime: form.regulationArgs.duration[0]
                  }"
                ></el-time-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item
                label="高速路(km/h)"
                v-if="redioType === 2"
                prop="regulationArgs.highWaySpeed"
              >
                <el-input
                  v-model="form.regulationArgs.highWaySpeed"
                  placeholder="请输入(0表示无限速）"
                  maxlength="18"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item
                label="都市高速路(km/h)"
                v-if="redioType === 2"
                prop="regulationArgs.urbanHighway"
              >
                <el-input
                  v-model="form.regulationArgs.urbanHighway"
                  placeholder="请输入(0表示无限速）"
                  maxlength="18"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item
                label="国道(km/h)"
                v-if="redioType === 2"
                prop="regulationArgs.countyRoad"
              >
                <el-input
                  v-model="form.regulationArgs.countyRoad"
                  placeholder="请输入(0表示无限速）"
                  maxlength="18"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item
                label="省道(km/h)"
                v-if="redioType === 2"
                prop="regulationArgs.provincialRoad"
              >
                <el-input
                  v-model="form.regulationArgs.provincialRoad"
                  placeholder="请输入(0表示无限速）"
                  maxlength="18"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <el-form-item label="县道(km/h)" v-if="redioType === 2" prop="regulationArgs.stateRoad">
                <el-input
                  v-model="form.regulationArgs.stateRoad"
                  placeholder="请输入(0表示无限速）"
                  maxlength="18"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item
                label="速度上限(km/h)"
                v-if="redioType === 3"
                prop="regulationArgs.maxSpeed"
              >
                <el-input
                  v-model="form.regulationArgs.maxSpeed"
                  placeholder="请输入(0表示无限速）"
                  maxlength="18"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="作业类型:" v-if="redioType === 3" prop="regulationArgs.jobType">
                <el-radio-group v-model="form.regulationArgs.jobType">
                  <el-radio :label="0">机械化清扫</el-radio>
                  <el-radio :label="1">机械化清洗</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item
                label="最高速度(km/h)"
                v-if="redioType === 1"
                prop="regulationArgs.maxSpeed"
              >
                <el-input
                  v-model="form.regulationArgs.maxSpeed"
                  placeholder="请输入(0表示无限速）"
                  maxlength="18"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item
                label="最低速度(km/h)"
                v-if="redioType === 1"
                prop="regulationArgs.miniSpeed"
              >
                <el-input
                  v-model="form.regulationArgs.miniSpeed"
                  placeholder="请输入(0表示无限速）"
                  maxlength="18"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item
                label="离岗时限(min)"
                v-if="redioType === 4"
                prop="regulationArgs.timeLimit"
              >
                <el-input v-model="form.regulationArgs.timeLimit" placeholder="请输入" maxlength="18"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item
                label="静止时长(min)"
                v-if="redioType === 5"
                prop="regulationArgs.timeLimit"
              >
                <el-input v-model="form.regulationArgs.timeLimit" placeholder="请输入" maxlength="18"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item
                label="限制类型:"
                v-if="redioType === 0"
                prop="regulationArgs.restrictionType"
              >
                <el-radio-group v-model="form.regulationArgs.restrictionType">
                  <el-radio :label="'in'">禁入</el-radio>
                  <el-radio :label="'out'">禁出</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="类型:" v-if="redioType === 0 || redioType === 1" prop="areaType">
                <el-radio-group v-model="form.regulationArgs.areaType">
                  <el-radio :label="0">自定义</el-radio>
                  <el-radio :label="1">行政区域</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="报警等级:" prop="alarmLevel">
                <el-radio-group v-model="form.alarmLevel">
                  <el-radio :label="0">低风险</el-radio>
                  <el-radio :label="1">中风险</el-radio>
                  <el-radio :label="2">高风险</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item
                label="是否拍照:"
                v-if="redioType === 0 || redioType === 1 || redioType === 2 || redioType === 3"
                prop="regulationArgs.isPhoto"
              >
                <el-radio-group v-model="form.regulationArgs.isPhoto">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item
                label="拍照通道"
                v-if="redioType === 0 || redioType === 1 || redioType === 2 || redioType === 3"
                prop="regulationArgs.photoChn"
              >
                <el-select
                  v-model="form.regulationArgs.photoChn"
                  placeholder="请选择"
                  clearable
                  disabled
                >
                  <el-option
                    v-for="(item, idx) in $enums.VideoPerspective.listString"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item
                label="录像时长(秒)"
                v-if="redioType === 0 || redioType === 1 || redioType === 2 || redioType === 3"
                prop="regulationArgs.videoDuration"
              >
                <el-input
                  v-model="form.regulationArgs.videoDuration"
                  placeholder="请输入"
                  maxlength="18"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item
                label="录像通道"
                v-if="redioType === 0 || redioType === 1 || redioType === 2 || redioType === 3"
                prop="regulationArgs.videoChn"
              >
                <el-select
                  v-model="form.regulationArgs.videoChn"
                  placeholder="请选择"
                  clearable
                  disabled
                >
                  <el-option
                    v-for="(item, idx) in $enums.VideoPerspective.listString"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="报警语音" prop="alarmVoice">
                <el-input
                  type="textarea"
                  v-model="form.alarmVoice"
                  placeholder="请输入"
                  maxlength="64"
                  :autosize="{ minRows: 4, maxRows: 6}"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="tac" style="margin-top: 40px">
        <el-button type="success" class="btn-success-hw" @click="onSubmit">保存</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关 闭</el-button>
      </div>
    </div>
    <el-dialog
      title="新增区域"
      :visible.sync="dialogVisible"
      width="70%"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :before-close="handleClose"
      center
      top="none"
    >
      <div class="container">
        <div class="container-l">
          <el-table :data="areaTableData" border style="width: 100%">
            <el-table-column prop="regionName" width="180px" label="区域名称"></el-table-column>
            <el-table-column prop="gmtCreate" align="center" label="时间">
              <template slot-scope="scope">{{scope.row.gmtCreate| str2ymd}}</template>
            </el-table-column>
            <el-table-column prop="address" align="center" label="操作">
              <template slot-scope="scope">
                <el-button @click="areaInfo(scope.row)" type="text" size="small">查看</el-button>
                <el-button @click="deleteArae(scope.row)" type="text" size="small">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="container-r">
          <div class="add-wrap">
            <!-- <span></span> -->
            <el-input style="width: 28%" v-model="newAreaName" placeholder="请输入区域名称"></el-input>
            <el-button type="primary" size="mini" @click="newAreaData">新增区域</el-button>
          </div>
          <!-- 地图部分 -->
          <div class="map-wrap">
            <area-map v-model="mapData" :accuracy="6" @change="onMapChange"></area-map>
          </div>
          <div></div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
  </div>
</template>

<script>
import addForDialog from "@/mixins/add-for-dialog";
import AreaMap from "./AreaMap";
export default {
  mixins: [addForDialog],
  components: { AreaMap },
  props: {
    dataFromDialog: {
      //从弹窗获得传来的参数等数据，！！！！名称固定！！！！
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    const vm = this;
    // const validateContactPhone = (rule, value, callback) => {
    //   if (!value || !/^[1][0-9][0-9]{9}$/.test(value)) {
    //     callback(new Error("请输入11位手机号码"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      nav: [{ name: "编辑作业规则" }],
      debug: 1,
      dialogVisible: false,
      submitLoading: false,
      areaOption: [],
      redioType: null, //类型变量，控制规则内容
      areaData: null,
      form: {
        regulationType: null, //规则类别
        regulationName: null, //规则名称
        alarmVoice: null, //报警语音
        alarmLevel: null, //报警等级
        regulationArgs: {
          areaId: 0, //区域名称
          duration: [null, null], //时间范围
          highWaySpeed: null, //高速路
          urbanHighway: null, //都市高速路
          countyRoad: null, //国道
          provincialRoad: null, //省道
          stateRoad: null, //县道
          maxSpeed: null, //速度上限/最高速度
          miniSpeed: null, //最低速度
          timeLimit: null, //离岗时限/静止时长
          restrictionType: null, //限制类型
          jobType: null, //作业类型
          areaType: null, //类型
          isPhoto: null, //是否拍照
          photoChn: null, //拍照通道，固定通道2，司机摄像头
          videoDuration: null, //录像时长
          videoChn: null, //录像通道，固定通道2，司机摄像头
        },
      },
      rules: {
        regulationType: {
          required: true,
          message: "必填字段不能为空",
          trigger: "change",
        },
        regulationName: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur",
        },
        "regulationArgs.areaId": {
          required: true,
          message: "必填字段不能为空",
          trigger: "change",
        },
        "regulationArgs.duration": {
          required: true,
          message: "必填字段不能为空",
          trigger: "change",
        },
        "regulationArgs.highWaySpeed": {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur",
        },
        "regulationArgs.urbanHighway": {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur",
        },
        "regulationArgs.countyRoad": {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur",
        },
        "regulationArgs.provincialRoad": {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur",
        },
        "regulationArgs.stateRoad": {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur",
        },
        "regulationArgs.maxSpeed": {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur",
        },
        "regulationArgs.miniSpeed": {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur",
        },
        "regulationArgs.timeLimit": {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur",
        },
        "regulationArgs.restrictionType": {
          required: true,
          message: "必填字段不能为空",
          trigger: "change",
        },
        "regulationArgs.jobType": {
          required: true,
          message: "必填字段不能为空",
          trigger: "change",
        },
        "regulationArgs.areaType": {
          required: true,
          message: "必填字段不能为空",
          trigger: "change",
        },
        alarmLevel: {
          required: true,
          message: "必填字段不能为空",
          trigger: "change",
        },
        "regulationArgs.isPhoto": {
          required: true,
          message: "必填字段不能为空",
          trigger: "change",
        },
        "regulationArgs.photoChn": {
          required: true,
          message: "必填字段不能为空",
          trigger: "change",
        },
        "regulationArgs.videoDuration": {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur",
        },
        "regulationArgs.videoChn": {
          required: true,
          message: "必填字段不能为空",
          trigger: "change",
        },
        alarmVoice: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur",
        },
      },
      areaTableData: [],
      newAreaName: null,
      mapData: {
        polygonScope: null, //（1个）多边形具体的点，形如  [[118,36],[117,32],[116,113]]
        scope: null, //（1个）围栏范围，经纬度点阵数组，形如  [[118,36],[117,32],[116,113]]
      },
    };
  },
  watch: {
    form: {
      handler(val) {
        this.redioType = val.regulationType;
        console.log(this.redioType);
      },
      deep: true, //true 深度监听
    },
  },
  methods: {
    // tabs切换
    redioChange(val) {
      this.redioType = val;
    },

    // 提交数据
    async submit() {
      let self = this;
      //提交form数据
      console.log(this.form);
      let params = JSON.parse(JSON.stringify(this.form));
      delete params.gmtCreate;
      delete params.gmtModified;
      await this.$api_hw
        .operationSafetyManagement_regulationInfoUpdate({
          sanitationRegulationInfoVO: params,
        })
        .then((res) => {
          BUS.$emit(BUSEVENT.REFRESH_OPERATING_RULES);
        });
    },

    // 新增区域名称
    addAreaName() {
      this.dialogVisible = true;
      this.getAreaData();
    },

    handleClose(done) {
      done();
    },
    onMapChange(mapData) {
      if (mapData) this.form.bidRealArea = "" + mapData.polygonArea;
    },

    // -----------地图区域弹窗------------
    // 添加区域数据
    newAreaData() {
      if (!this.newAreaName) {
        this.$message({
          message: "警告，新增区域名称不能为空",
          type: "warning",
        });
        return;
      }
      if (!this.mapData.polygonScope) {
        this.$message({
          message: "警告，必须绘制新增区域范围",
          type: "warning",
        });
        return;
      }
      this.$api_hw
        .operationSafetyManagement_insertRegionInfo({
          insertType: 2,
          regionInfoVOIOV: {
            regionArgs: this.mapData.polygonScope,
            regionBelong: 1,
            regionName: this.newAreaName,
            regionType: "多边形",
          },
        })
        .then((res) => {
          this.getAreaData();
          this.newAreaName = null;
          this.mapData.polygonScope = null;
          this.$notify({
            title: "成功",
            message: "新增成功",
            type: "success",
          });
        });
    },

    // 区域数据列表
    async getAreaData() {
      this.areaTableData = await this.$api_hw.operationSafetyManagement_listDefaultRegionInfoByTenantId(
        {
          currentPage: 1,
          pageSize: 200,
          regionBelong: 1,
          regionType: "多边形",
        }
      );
      this.areaOption = this.areaTableData.map((item) => {
        return {
          value: item.regionName,
          label: item.regionName,
        };
      });
    },

    // 查看
    async areaInfo(val) {
      let area = await this.$api_hw.operationSafetyManagement_getRegionInfoByRegionId(
        {
          regionId: val.regionId,
        }
      );
      this.log(area);
      try {
        this.mapData.polygonScope = area.regionArgs;
        this.mapData.move2center = true;
      } catch (error) {
        this.$message.error("获取区域范围失败");
      }
    },

    // 删除
    deleteArae(val) {
      this.$api
        .DeleteRegionInfo({ regionIdList: [val.regionId] })
        .then((res) => {
          this.getAreaData();
        });
    },
  },
  async mounted() {
    this.getAreaData();
    console.log(this.dataFromDialog);
    this.form = await this.$api_hw.operationSafetyManagement_regulationInfoGetRegulationInfoByRegulationId(
      {
        regulationId: this.dataFromDialog.id,
      }
    );
    console.log(this.form);
  },
};
</script>

<style lang='sass' scoped>
.content-box
  overflow: inherit

.content-box .content-main,
.content-box
  padding: 0

/deep/ el-form
  display: flex;
  flex-wrap: wrap;
/deep/ .el-form-item
  display: flex;
  width: 100%;
/deep/ .el-select,
/deep/ .el-cascader--mini,
/deep/ .el-date-editor.el-input,
/deep/ .el-range-editor--mini.el-input__inner
  width: 100%;
/deep/ .el-form-item--mini .el-form-item__content
  flex: 1;
/deep/ .el-form-item--mini .el-form-item__label 
  text-align: right;
  line-height: 34px;

/deep/ .el-radio:last-child
  margin-right: 20px;

/deep/ .time-step .el-form-item__content
  display: flex

/deep/ .el-input.is-disabled .el-input__inner
  color: #333d42

.area-select /deep/ .el-form-item__content
  display: flex
  .el-button--mini
    height: 28px;
    border-left: none
    margin-left: -2px;
  .el-input--mini .el-input__inner
    border-radius: 4px 0 0 4px



/deep/ .el-dialog__body
    padding-top: 15px
    width: 100%
    height: calc(100% - 54px)

/deep/ .el-dialog
    height: 80vh
.container
  display: flex
  width: 100%
  height: 100%
.container-l
  width: 400px
  height: 100%
  margin-right: 20px
  // background: #eee
  overflow-y: auto
.container-r
  flex: 1
  height: 100%
  .map-wrap
    height: calc(100% - 28px)!important
    padding-top: 20px
  .add-wrap /deep/ .el-input--mini .el-input__inner
    border-radius: 4px 0 0 4px
  .add-wrap /deep/ .el-button--mini
    height: 28px;
    border-left: none
    margin-left: -2px;

/deep/ .el-dialog__wrapper
    display: flex;
    align-items: center;
/deep/.el-dialog__wrapper .el-dialog
    margin: 0 auto!important
</style>